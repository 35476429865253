<template>
    <div id="effectiveness-dashboard">
        <div class="vx-row">
            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                        Reporte de <span>efectividad</span>.
                    </h1>
                </vx-card>
            </div>

            <!-- TABLE -->
            <div class="vx-col w-full">
                <vx-card>
                    <vs-table search :data="data" noDataText="No hay datos disponibles.">
                        <div slot="header">
                            <h4 class="mb-4">Agrupar por:</h4>
                            <div class="flex ml-4">
                                <div class="vx-row">
                                    <div class="vx-col w-full mb-6">
                                        <ul class="centerx">
                                            <li>
                                                <vs-radio color="primary" v-model.number="filterSelected" vs-value="0">Por Proveedor</vs-radio>
                                            </li>
                                            <li>
                                                <vs-radio color="primary" v-model.number="filterSelected" vs-value="1">Por Zona</vs-radio>
                                            </li>
                                            <li>
                                                <vs-radio color="primary" v-model.number="filterSelected" vs-value="2">Por Región</vs-radio>
                                            </li>
                                            <li>
                                                <vs-radio color="primary" v-model.number="filterSelected" vs-value="3">Por Líder de venta</vs-radio>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <vs-button @click.stop="getReport()" type="filled" class="mt-6 ml-8" icon="search"></vs-button>
                            </div>
                            <p>
                                Se muestra el % de tasa de conversión, así como los tiempos promedio en días para cada una de las etapas del Prospecto.
                                <br>
                                <strong>Nota:</strong> En caso de que el proveedor haya notificado tener problemas de comunicación 
                                con el prospecto, será descartado dicho prospecto para la estadística de este reporte.
                            </p>
                        </div>
                        <template slot="thead">
                            <template v-for="(col, idx) in columns" >
                                <vs-th class="bold" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >
                                    {{col.title}} {{col.needArrow ? '&#8594;' : ''}}
                                </vs-th>
                                <!-- <vx-tooltip 
                                    v-if="idx > 0"
                                    v-bind:key="idx"
                                    title="¿Cómo se calcula?"
                                    color="dark"
                                    class="m-0"
                                    style="cursor:pointer;"
                                    :text="col.textTooltip">
                                    <vs-chip color="dark">?</vs-chip>
                                </vx-tooltip> -->
                            </template>
                        </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td class="bold">
                                        {{tr.item_name}}
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{(tr.stats.effectiveness * 100).toFixed(2)}} %
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center" :style="isOverMaxRate(tr.stats.not_contact,'assigned_not_contacted') ? 'color: red;':''">
                                            {{tr.stats.not_contact.toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center" :style="isOverMaxRate(tr.stats.dismiss,'not_interested') ? 'color: red;':''">
                                            {{tr.stats.dismiss.toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center" :style="isOverMaxRate(tr.stats.interested,'interested') ? 'color: red;':''">
                                            {{tr.stats.interested.toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center" :style="isOverMaxRate(tr.stats.quoted,'quoted') ? 'color: red;':''">
                                            {{tr.stats.quoted.toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{tr.stats.withReq.toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{parseFloat(tr.stats.approved_tp).toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{parseFloat(tr.stats.funding_tp).toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{parseFloat(tr.stats.delivered_tp).toFixed(0)}}
                                        </p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="text-center">
                                            {{calculateTotal(tr).toFixed(0)}}
                                        </p>
                                    </vs-td>
                                </vs-tr>
                            </template>
                    </vs-table>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>

const columns = [
    { title: "", key: "item", needArrow: false, sortable: false}
    , { title: "TASA DE CONVERSIÓN", key: "effectiveness", needArrow: false, sortable: false, textTooltip: "Todos los proyectos entregados dividido entre el universo de prospectos." }
    , { title: "EN CONTACTAR", key: "assigned_not_contacted", needArrow: true, sortable: false, textTooltip: "Tiempo promedio sin contactar desde asignados." }
    , { title: "DESCARTADO", key: "not_interested", needArrow: true, sortable: false, textTooltip: "Tiempo promedio de cambio de estado. Universo: Prospectos segmentados por estado." }
    , { title: "INTERESADO", key: "interested", needArrow: true, sortable: false, textTooltip: "Tiempo promedio de cambio de estado. Universo: Prospectos segmentados por estado." }
    , { title: "CON PROPUESTA", key: "quoted", needArrow: true, sortable: false, textTooltip: "Tiempo promedio de cambio de estado. Universo: Prospectos segmentados por estado." }
    , { title: "CON SOLICITUD", key: "with_req", needArrow: true, sortable: false, textTooltip: "Tiempo promedio de cambio de estado. Universo: Prospectos segmentados por estado." }
    , { title: "APROBADO", key: "approved", needArrow: true, sortable: false, textTooltip: "Tiempo promedio entre fecha de aprobación y fecha de entrega de solicitud completa. Universo: Proyectos en aprobados en adelante." }
    , { title: "FONDEO", key: "funding", needArrow: true, sortable: false, textTooltip: "Tiempo promedio entre fecha de fondeo y fecha de entrega de solicitud completa. Universo: Proyectos en fondeo en adelante." }
    , { title: "INSTALACIÓN", key: "delivered", needArrow: false, sortable: false, textTooltip: "Tiempo promedio entre fecha de entrega y fecha de instalación de proyecto. Universo: Proyectos entregados." }
    , { title: "TOTAL", key: "total", needArrow: false, sortable: false, textTooltip: "" }
];

export default {
    data() {
        return {
            data: [],
            columns: columns,
            filterSelected: 0
            , isMounted: false
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
            , supplierSelected: 0
            , collections: {
               suppliers: []
            }
        }
    },
    async mounted() {
        const {data} = await axios.get(`/api/pgm/me/programs`)
        this.programs = data;
        this.getReport();
        this.getCollections();
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        getCountriesList() {
			return this.collections.suppliers.map((supplier) => ({ value: supplier.id, text: supplier.select_name }));
		},
        programIdSelected () {
            return this.currentProgramData.id;
        }
    },
    methods: {
        async getReport() {
            this.showLoading(true);
            this.isMounted = false;
            let type;

            switch(this.filterSelected){
                case 0: type = "by-supplier"; break;
                case 1: type = "by-zone"; break;
                case 2: type = "by-region"; break;
                case 3: type = "by-subregion"; break;
            }

            try {
                const response = await axios.get(`/api/analytics/pgm/demographic/effectiveness?type=${type}&programId=${this.currentProgramData.id}`);
                this.data = response.data;
                
            } catch (error) {
                this.errorNotif(
                    "Error al consultar el reporte de efectividad.",
                    "Ha ocurrido un error, por favor intente más tarde"
                );
                this.showLoading(false);
            }
            this.showLoading(false);
            this.isMounted = true;
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
        async getCollections(){
            try {
                let collectionsObjects = ['getPGMSuppliers'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.suppliers = res.data.getPGMSuppliers;
                this.collections.suppliers.unshift({
                    id: 0,
                    select_name: "Todos los proveedores"
                });
            }
            catch (e) {
                this.warn(e);
            }
        },
        calculateTotal(currentItem) {
            return parseFloat(currentItem.stats.tp_avg) + parseFloat(currentItem.stats.approved_tp) + parseFloat(currentItem.stats.funding_tp) + parseFloat(currentItem.stats.delivered_tp);
        },
        isOverMaxRate(value, type){
            const maxRates = [7,30]
            let flag;
            switch(type){
                case "assigned_not_contacted" : 
                flag = value >= maxRates[0];
                break;
                case "not_interested": 
                flag = value >= maxRates[1];
                break;
                case "interested": 
                flag = value >= maxRates[0];
                break;
                case "quoted": 
                flag = value >= maxRates[0];
                break;
                default: flag = false;
            }

            return flag;
        }
    }
}
</script>

<style lang="css">

</style>